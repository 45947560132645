import React, { useCallback, useContext, useRef, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { CartContext } from "../../../context/CartContext";
import { ReactComponent as IconCart } from "../../../assets/cart.svg";
import { ReactComponent as LogoSvg } from "../../../assets/logo.svg";
import { ReactComponent as IconPremium } from "../../../assets/premium.svg";
import { ReactComponent as IconOutStock } from "../../../assets/outStock.svg";
import classes from "./ProductItem.module.css";
import { rate } from "../../../data";
import QuickView from "../../quickView/QuickView";

const getProductImage = ({ img, flag, name, showFinalModalHandler, quickViewModal }) => {
  try {
    return (
      <>
        {flag && <span className={classes.flagImage}>{flag} <IconPremium /></span>}
        <img className={classes.image} src={require(`../../../assets/imgs-produtos/${img}`)} alt={name} width="200px" height="200px" onClick={showFinalModalHandler} />
        <div className={classes.maximizeIcon} onClick={showFinalModalHandler}>⛶</div>
        {quickViewModal && <QuickView name={name} img={img} onClose={showFinalModalHandler} />}
      </>
    );
  } catch (error) {
    return <div className={classes.svg}><LogoSvg /></div>;
  }
};

const ProdutoItem = ({ name, img, flag, variables = [], refID = '', price, installmentQtd, description }) => {
  const [amount, setAmount] = useState(1);
  const [quickViewModal, setQuickViewModal] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState(null); // Estado para o item selecionado
  const animaTimeout = useRef();

  const { addItem } = useContext(CartContext);
  const { showAlert } = useContext(AlertContext);

  const addItemToCartHandler = useCallback((e) => {
    e.preventDefault();

    clearTimeout(animaTimeout.current);

    if (!selectedVariable) {
      showAlert(`Não foi possivel adicionar o item "${name}" ao carrinho, selecione o numero`);
      return
    }
    addItem({
      name,
      price,
      installmentQtd,
      variables: selectedVariable,
      amount: +amount,
      refID: refID,
      img,
      flag
    });

    showAlert(`${amount} "${name} - Número ${selectedVariable}" adicionado(s) ao carrinho`);
    setAmount(1);
    setSelectedVariable(null)
  }, [amount, name, price, installmentQtd, refID, img, flag, addItem, showAlert, selectedVariable]);

  const showFinalModalHandler = () => {
    setQuickViewModal(!quickViewModal);
  };
  const handleSelect = (variable) => {
    setSelectedVariable(variable); // Define o item selecionado
  };

  // Funções para alterar a quantidade
  const incrementAmount = () => setAmount((prev) => prev + 1);
  const decrementAmount = () => setAmount((prev) => Math.max(prev - 1, 1));

  return (
    <li className={classes.card}>
      <div className={classes.imgContainer}>
        {getProductImage({ img, flag, name, showFinalModalHandler, quickViewModal })}
      </div>
      <div className={classes.infos}>
        <div>
          <p className={classes.name}>{name}</p>
          {description &&
            <p className={classes.description} title={description}>
              {description}
            </p>
          }
        </div>

        {(variables?.length !== 0 && price?.length !== 0) ?
          <>
            <div className={classes.flex}>
              <div className={classes.block}>
                {installmentQtd && <p className={classes.installmentQtd}>{installmentQtd} x de R$ {((Number(price) + rate) / installmentQtd).toFixed(2)}</p>}
                <p className={classes.price}>R$ {price}</p>
              </div>
              <form className={classes.amountForm}>
                <button
                  className={classes.btnAddRemove}
                  onClick={(e) => { e.preventDefault(); decrementAmount(); }}
                  title="adicionar produto"
                >
                  -
                </button>
                <label htmlFor={`amount-${name}`}>
                  <span>Quantidade</span>
                  <input
                    type="number"
                    name="quantidade"
                    id={`amount-${name}`}
                    value={amount}
                    onChange={(e) => setAmount(Math.max(Number(e.target.value), 1))}
                    step="1"
                    min="1"
                    readOnly
                  />
                </label>
                <button
                  className={classes.btnAddRemove}
                  onClick={(e) => { e.preventDefault(); incrementAmount() }}
                  title="Remover carrinho"
                >
                  +
                </button>
              </form>
            </div>
            <div className={classes.variableContent}>
              <span> Selecione o Tamanho</span>
              <div className={classes.variable}>
                {variables?.map((variable) => (
                  <button
                    key={variable}
                    className={`${classes.btnVariable} ${selectedVariable === variable ? classes.selected : ''}`}
                    onClick={() => handleSelect(variable)} // Define a seleção
                    title="Selecionar tamanho"
                  >
                    {variable}
                  </button>
                ))}
              </div>

            </div>
            <button
              className={`${classes.btnAddToCart} ${classes.btn}`}
              onClick={addItemToCartHandler}
              title="adicionar ao carrinho"
            >
              <span className={classes.value}>Adicionar</span>
              <IconCart />
            </button>
          </>
          :
          <p className={classes.outOfStock} title={description}>
            <IconOutStock />
            Item Sem Estoque
          </p>
        }
      </div>
    </li>
  );
};

export default ProdutoItem;
